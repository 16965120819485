<template>
  <div class="app-content content" :class="[{'show-overlay': $store.state.app.shallShowOverlay}]">
    <div class="content-overlay"/>
    <div class="header-navbar-shadow"/>
    <div class="content-wrapper">
      <div class="content-body">
        <section id="dashboard-ecommerce">
          <div class="row match-height">
            <div class="col" v-if="balance && balance.client">
              <div class="card text-center">
                <div class="card-body">
                  <b-avatar class="mb-1" variant="light-info" size="45">
                    <feather-icon size="21" icon="InfoIcon" />
                  </b-avatar>
                  <div class="truncate">
                    <h2 class="mb-25 font-weight-bolder">
                      <div class="text-nowrap" v-for="(balance, index) in balance.client" :key="index">
                      <span v-if="balance.amount !== 0" :class="['amount', balance.amount > 0 ? 'positive' : 'negative']">
                        {{ balance.amount }}
                      </span> <span v-if="balance.amount !== 0" class="currency">{{ balance.currency_name }}</span>
                      </div>
                    </h2>
                    <span>Баланс</span>
                  </div>
                </div>

              </div>
            </div>
            <div class="col" v-if="balance && balance.supplier">
              <div class="card text-center">
                <div class="card-body">
                  <b-avatar class="mb-1" variant="light-success" size="45">
                    <feather-icon size="21" icon="InfoIcon" />
                  </b-avatar>
                  <div class="truncate">
                    <h2 class="mb-25 font-weight-bolder">
                      <div class="text-nowrap" v-for="(balance, index) in balance.supplier" :key="index">
                      <span v-if="balance.amount !== 0" :class="['amount', balance.amount > 0 ? 'positive' : 'negative']">
                        {{ balance.amount }}
                      </span> <span v-if="balance.amount !== 0" class="currency">{{ balance.currency_name }}</span>
                      </div>
                    </h2>
                    <span>Баланс Поставщика</span>
                  </div>
                </div>

              </div>
            </div>
            <div class="col-12 col-md-7">
              <ecommerce-profit-chart />
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import { BAvatar } from 'bootstrap-vue';
import EcommerceProfitChart from '@/views/dashboard/ecommerce/EcommerceProfitChart.vue';

export default {
  name: 'Dashboard',
  components: { EcommerceProfitChart, BAvatar },
  data() {
    return {
      loading: false,
      balance: null,
    };
  },
  async mounted() {
    this.balance = (await this.$api.account.balance()).data;
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';

.amount {
  white-space: nowrap;

& span.amount {
    font-size: 18px;

  }

& span.currency {
    font-size: 10px;
  }
}

.row-green {
  background-color: #e6ffe6;
}

.row-warning {
  background-color: #fff3cd;
}
span.amount {
  font-size: 18px;
}
.text-dashed {
  border-bottom: 1px dotted #6c6c6c;
  cursor: pointer;
}
.positive {
  color: $success;
}
.negative {
  color: $danger;
}
span.currency {
  font-size: 10px;
}
</style>
